// apps/web/src/app/_services/config.service.ts
import { Injectable } from '@angular/core'
import { CrudService } from 'app/_model-services/crud.service'
import { BehaviorSubject, firstValueFrom, map } from 'rxjs'
import { Config } from '@repo/models'

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private configSource = new BehaviorSubject<Config | null>(null)
    public config$ = this.configSource.asObservable()
    private configLoaded = false

    constructor(private crudService: CrudService) {}

    get config(): Config {
        return this.configSource?.value
    }

    async loadConfig(forceRefresh = false): Promise<Config> {
        if (!this.configLoaded || forceRefresh) {
            try {
                const data = await firstValueFrom(this.crudService.read('config').pipe(map((res) => res.data)))
                this.configSource.next(data)
                this.configLoaded = true
            } catch (error) {
                console.error('Error loading configuration:', error)
            }
        }
        return this.config
    }

    async updateConfig(config: Partial<Config>) {
        try {
            if (!this.config?._id) {
                await this.loadConfig()
            }

            const data = await firstValueFrom(this.crudService.update('config', this.config._id, config).pipe(map((res) => res.data)))
            this.configSource.next(data)
            return data
        } catch (error) {
            console.error('Error updating configuration:', error)
            throw error
        }
    }
}
